import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Price" } }, [_c("text-input", { key: "ItemCode", attrs: { "label": "Item Code", "placeholder": "Type Item Code", "form-item": "", "rules": "required", "span": 12 } }), _c("select-input", { key: "Currency", attrs: { "label": "Currency", "reference": "web-analytics.common.ppvfw-currencies", "placeholder": "Select Currency", "source": "currency", "rules": "required", "span": 12, "form-item": "", "default-active-first-option": "" } }), _c("select-input", { key: "CountryID", attrs: { "label": "Country", "data-source": _vm.countries, "placeholder": "Select Country", "source": "countryId", "source-label": "country", "value": _vm.countryId, "rules": "required", "span": 12, "form-item": "", "disabled": true } }), _c("text-input", { key: "Price", attrs: { "label": "Price", "placeholder": "Type Price", "form-item": "", "rules": _vm.regex10digits5frac, "max-length": 16, "span": 12 } }), _c("select-input", { key: "IntStartDate", attrs: { "reference": "web-analytics.common.ppvfw-fin-period-future-only", "rules": "required", "label": "Start Date", "placeholder": "Select Start Date", "source": "intStartDate", "source-label": "finPeriodChar", "span": 12, "default-active-first-option": "" } }), _c("select-input", { key: "IntEndDate", attrs: { "reference": "web-analytics.common.ppvfw-fin-period-future-only", "rules": "required", "label": "End Date", "placeholder": "Select End Date", "source": "intEndDate", "source-label": "finPeriodChar", "span": 12, "default-active-first-option": "" } })], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreatePrices",
  inject: ["crud"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      countryId: this.crud.getQueryString("CountryID").value
    };
  },
  created() {
    this.getCountries();
    this.storeValue("UpdateType", "add");
    this.storeValue("CountryID", this.countryId);
  },
  methods: {
    parseIntStartDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intStartDate}`).format("YYYY/MM/DD");
    },
    parseIntEndDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intEndDate}`).format("YYYY/MM/DD");
    },
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreatePrices = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-currencies", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-gl-codes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-fin-period-future-only", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.input-prices", "resource-id-name": "costID", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/prices" } }, [_c("create-prices")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreatePrices
  },
  data() {
    return {
      CreatePrices,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
